import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/app/src/components/layout/index.jsx";
import MultiBrand from '@images/svg/multiBrand.svg';
import { FamilyLogos } from '@sainsburys-tech/fable';
export const _frontmatter = {};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const TableOfContents = makeShortcode("TableOfContents");
const Tabs = makeShortcode("Tabs");
const Tab = makeShortcode("Tab");
const LogosContainer = makeShortcode("LogosContainer");
const Section = makeShortcode("Section");
const FeedbackThumbs = makeShortcode("FeedbackThumbs");
const GetInTouch = makeShortcode("GetInTouch");
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <TableOfContents title="On this page" mdxType="TableOfContents">
      <div className="table-of-contents">
        <ul>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#how-to-use-logos"
            }}>{`How to use logos`}</a></li>
          <li parentName="ul"><a parentName="li" {...{
              "href": "#our-multi-brand-lock-up-device"
            }}>{`Our multi-brand lock-up device`}</a></li>
        </ul>
      </div>
    </TableOfContents>
    <h2 {...{
      "id": "how-to-use-logos",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-logos",
        "aria-label": "how to use logos permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`How to use logos`}</h2>
    <p>{`When using logos:`}</p>
    <ul>
      <li parentName="ul">{`Check that you’re using the right logo in the right place`}</li>
      <li parentName="ul">{`Provide an accessible name so that users who can’t visually perceive the logo can understand what it is`}</li>
      <li parentName="ul">{`Include the word ‘logo’ in the accessible name, unless the logo is in the header`}</li>
      <li parentName="ul">{`Include the link destination in the accessible name if the logo’s also a link`}</li>
    </ul>
    <h4>{`Following brand guidelines`}</h4>
    <p>{`There’s more information on logos in the `}<a parentName="p" {...{
        "href": "/resources#brand-resources"
      }}>{`brand guidelines in the resources section.`}</a>{` This includes minimum sizes, spacing and more guidance on when to use which logo.`}</p>
    <p>{`If you have any questions about logo use or want to use a logo in a new way, it’s best to attend a brand clinic session.`}</p>
    <h4>{`Example logos`}</h4>
    <Tabs mdxType="Tabs">
  <Tab title="Sainsbury's" mdxType="Tab">
    <LogosContainer logo="SainsburysHelpingEveryoneEatBetter" title="'Helping everyone eat better' logo" children="To be used when our brand promise is relevant and legally applicable" mdxType="LogosContainer" />
    <LogosContainer logo="Sainsburys" title="Masterbrand logo" children="To be used when 'Helping everyone eat better' isn't relevant or legally applicable" mdxType="LogosContainer" />
    <LogosContainer logo="SainsburysBank" title="Sainsbury’s Bank logo" mdxType="LogosContainer" />
    <LogosContainer logo="SainsburysChopChop" title="Chop Chop Logo " mdxType="LogosContainer" />
    <LogosContainer logo="SainsburysFoodToOrder" title="Food to order logo" children="For our special occasion food ordering service" mdxType="LogosContainer" />
    <LogosContainer logo="TogetherWeAreSainsburys" title="Internal logo" children="For internal experiences and communications to colleagues" mdxType="LogosContainer" />
    <LogosContainer logo="Jsainsburyplc" title="Corporate logo" children="For when we’re talking to our shareholders" mdxType="LogosContainer" />
    <LogosContainer logo="SainsburysSmartShop" title="SmartShop logo" mdxType="LogosContainer" />
  </Tab>
  <Tab title="Argos" mdxType="Tab">
    <LogosContainer logo="Argos" title="Masterbrand logo" children="Our primary logo for all customer-facing experiences and communications" width="125" mdxType="LogosContainer" />
    <LogosContainer logo="ArgosWordmark" title="Argos wordmark logo" children="For internal experiences and communications to colleagues" width="125" mdxType="LogosContainer" />
    <LogosContainer logo="ArgosFastTrack" title="Fast Track logo" width="125" mdxType="LogosContainer" />
    <LogosContainer logo="ArgosClickAndCollect" title="Click & Collect logo" width="125" mdxType="LogosContainer" />
  </Tab>
  <Tab title="Tu" mdxType="Tab">
    <LogosContainer logo="Tu" title="Masterbrand logo (black)" children="For use on white or light coloured backgrounds" width="100" mdxType="LogosContainer" />
  </Tab>
  <Tab title="Habitat" mdxType="Tab">
    <LogosContainer logo="Habitat" title="Masterbrand logo" children="For use on white or light coloured backgrounds" width="150" mdxType="LogosContainer" />
  </Tab>
  <Tab title="Nectar" mdxType="Tab">
    <LogosContainer logo="Nectar" title="Core logo" children="For use when the logo is a supporting asset" width="150" mdxType="LogosContainer" />
    <LogosContainer logo="NectarWordmark" title="Wordmark logo (purple)" children="For use when space is restricted" width="150" mdxType="LogosContainer" />
  </Tab>
    </Tabs>
    <h2 {...{
      "id": "our-multi-brand-lock-up-device",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#our-multi-brand-lock-up-device",
        "aria-label": "our multi brand lock up device permalink",
        "className": "c-title-link__anchor before"
      }}><svg parentName="a" {...{
          "xmlns": "http://www.w3.org/2000/svg",
          "className": "c-title-link__icon",
          "viewBox": "0 0 512.092 512.092"
        }}><path parentName="svg" {...{
            "d": "M312.45 199.6c-6.06-6.1-12.8-11.5-20.05-16.13-19.23-12.3-41.6-18.86-64.43-18.86-31.7-.04-62.1 12.55-84.48 35L34.94 308.23C12.6 330.6.05 360.93.05 392.55 0 458.53 53.44 512.05 119.42 512.1c31.65.1 62.03-12.43 84.4-34.83l89.6-89.6c1.62-1.6 2.53-3.8 2.52-6.1-.03-4.72-3.87-8.5-8.6-8.5h-3.4c-18.72.08-37.27-3.52-54.6-10.57-3.2-1.3-6.88-.57-9.3 1.88l-64.44 64.5c-20 20.02-52.43 20.02-72.44 0-20-20-20-52.43 0-72.44l108.97-108.88c20-19.97 52.38-19.97 72.37 0 13.47 12.68 34.48 12.68 47.95 0 5.8-5.8 9.3-13.5 9.9-21.68.63-9.77-2.98-19.34-9.9-26.28z"
          }}></path><path parentName="svg" {...{
            "d": "M477.06 35c-46.66-46.66-122.3-46.66-168.96 0l-89.5 89.42c-2.47 2.47-3.18 6.2-1.8 9.4 1.35 3.2 4.53 5.26 8.02 5.2h3.15c18.7-.04 37.22 3.58 54.53 10.66 3.2 1.32 6.87.57 9.3-1.88l64.26-64.17c20-20 52.44-20 72.45 0 20 20 20 52.45 0 72.45l-80.04 79.96-.68.77-28 27.82c-19.98 19.97-52.36 19.97-72.35 0-13.47-12.67-34.5-12.67-47.96 0-5.83 5.85-9.35 13.6-9.9 21.85-.62 9.78 2.98 19.35 9.9 26.28 9.88 9.92 21.43 18 34.13 23.9 1.8.85 3.6 1.53 5.38 2.3 1.8.77 3.67 1.37 5.46 2.05 1.8.68 3.67 1.28 5.46 1.8l5.04 1.36c3.4.85 6.82 1.53 10.32 2.13 4.22.6 8.46 1 12.72 1.18h6.47l5.13-.6c1.88-.08 3.84-.5 6.06-.5h2.9l5.9-.86 2.72-.5 4.95-1.03h.95c20.96-5.28 40.1-16.13 55.38-31.42l108.63-108.63c46.66-46.65 46.66-122.3 0-168.96z"
          }}></path></svg></a>{`Our multi-brand lock-up device`}</h2>
    <p>{`Our multi-brand lock-up is designed to make the connections between our brands clear, where it’s appropriate or useful for users.`}</p>
    <Section mdxType="Section">
  <FamilyLogos mdxType="FamilyLogos" />
    </Section>
    <h4>{`How to use the multi-brand lock-up device`}</h4>
    <p><strong parentName="p">{`Use the header 'Our brands'`}</strong><br />{`
You should add the words ‘Our brands’ either above or to the left of the multi-brand lock-up, if it benefits the user’s experience.`}</p>
    <p><strong parentName="p">{`Only include if there’s value`}</strong><br />{`
You should only use the multi-brand lock-up where there’s a clear value for the user. That could be because multiple brands appear within a journey, or to support a cross-brand campaign. If there’s no clear value in including it, don’t use it.`}</p>
    <p><strong parentName="p">{`Only include relevant logos`}</strong><br />{`
You should only include brand logos that are relevant to the journey. It’s not necessary or desirable to always show all logos together.`}</p>
    <p><strong parentName="p">{`Lead with the main logo`}</strong><br />{`
You should lead with the logo that is most relevant for the specific journey. For example, a journey around school uniforms might lead with Tu.`}</p>
    <p>{`If there’s no obvious ‘lead’ brand for the journey, lead with the Sainsbury’s logo, as it has the largest customer base.`}</p>
    <LogosContainer logo="SainsburysNectar" width="200" mdxType="LogosContainer">
  In this example, only Sainsbury’s and Nectar are relevant to the journey, with
  Sainsbury’s as the main brand.
    </LogosContainer>
    <hr></hr>
    <FeedbackThumbs mdxType="FeedbackThumbs" />
    <hr></hr>
    <GetInTouch mdxType="GetInTouch">
  We’re on hand to answer any questions you have or help you with your next
  project.
    </GetInTouch>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      